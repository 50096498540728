<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0, 0, 0, .5)'}"/>
      </Section>
      <Section limiter>
        <Row justify="between">
          <AccountMenu v-if="$laptop"
                       class="grid-md-4 grid-lg-4"/>
          <Column>
            <AccountContent class="grid-sm-full grid-md-8 grid-lg-8">
              <AccountDashboardAgency/>
            </AccountContent>
            <div class="account-view__become-guide">
              <FormationPortal
                class="account-view__formation-portal-title"
                :title="$t('organizations.make_org')"
                backgroundPosition="center right">
                <Button v-if="$agency.status === 'new'"
                        color="transparent"
                        v-on:click.native="$openPopupAddAgencies($agency)">
                  {{ $t('organizations.send_req') }}
                </Button>
                <Button v-if="$agency.status === 'moderated'"
                        color="transparent"
                        disabled>{{ $t('organizations.req_on_mod') }}
                </Button>
                <Button v-if="$agency.status === 'active'"
                        color="transparent"
                        v-on:click.native="$openPopupAddAgencies($agency)">
                  {{ $t('organizations.show_profile') }}
                </Button>
                <Button v-if="$agency.status === 'nonactive'"
                        color="red"
                        v-on:click.native="$openPopupAddAgencies($agency)">
                  {{ $t('organizations.req_declined') }}
                </Button>
              </FormationPortal>
            </div>
          </Column>
        </Row>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountAgencyView',
  data() {
    return {
      navigation: [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: '/account',
        title: this.$i18n.t('menu.profile'),
      }],
    };
  },
  created() {
    this.$store.dispatch('GET_AGENCY_BY_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      id: this.$route.params.id,
      lang: this.$i18n.locale,
    });
  },
  watch: {
    $route() {
      this.$store.dispatch('GET_AGENCY_BY_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        id: this.$route.params.id,
        lang: this.$i18n.locale,
      });
    },
  },
};
</script>
